import React, { useContext, useEffect, useState } from 'react'
import './Indicate.scss'
import MyContext from '../../Uttis/MyContext'
import { IoMdClose } from "react-icons/io";

const Indicate = () => {

    const {indi,setIndi} =useContext(MyContext)

    const[dn,setDn]=useState(false)

    useEffect(() => {

      !localStorage.getItem('sl') &&
      setIndi(true)
         setTimeout(() => {
          setDn(true);
    
          
         setTimeout(() => {
            setIndi(false);
            setDn(false)
          }, 6000);
    
          
        },1200);

        localStorage.setItem('sl','am')
    
        
      
      }, []);
  return (

<>

{indi &&
  <div className={`indicates-main ${dn ? 'indicates-down' : ''}`}>


   {dn?

    <div className='dn1'>
    <p>Select What You Need here</p> 
    <IoMdClose color='black' onClick={() => setDn(false) || setIndi(false)}/>
    <div className='arrow'></div>
    </div> : 
    
    <p>Choose</p>
  }

  
    
    </div>

}
 
    </>
  )
}

export default Indicate