import React from 'react'
import './Category.scss'
import { IoMdArrowDropdownCircle } from "react-icons/io"
import { ImHtmlFive } from "react-icons/im"
import {BsFiletypeCss,BsFillBootstrapFill} from "react-icons/bs"
import {IoLogoJavascript} from "react-icons/io"
import {SiJquery, SiExpress, SiNumpy, SiPython, SiMicrosoftexcel, SiTableau, SiPowerbi,SiMongodb, SiTailwindcss} from "react-icons/si"
import {GrFigma, GrReactjs} from "react-icons/gr"
import {IoLogoNodejs} from "react-icons/io"
import {TbSql} from "react-icons/tb"
import {CgFigma} from "react-icons/cg"



const Category = () => {
  

  const Link = {
color:'rgba(0,0,0,0.6)',
    pointerEvents: 'none',
    cursor: 'not-allowed'
  }



    


   
    return (
      
        <div className="navbar">
 
  <div className="dropdown">
    <button className="dropbtn">Frontend
      <IoMdArrowDropdownCircle/>
    </button>
    <div className="dropdown-content">
      <a href='https://v-extechsolution.in/learning/html/intro' target='_blank' >Html<ImHtmlFive/></a>
      <a href='https://v-extechsolution.in/learning/css/intro' target='_blank' >Css<BsFiletypeCss/></a>
      <a href='https://v-extechsolution.in/learning/css-advance/intro' target='_blank' >Css Advance<SiJquery/></a>
      <a href='https://v-extechsolution.in/learning/javascript/intro' target='_blank' >Java Script<IoLogoJavascript/></a>
      <a href='https://v-extechsolution.in/bootstrap/intro' target='_blank' style={Link}>Bootstrap<BsFillBootstrapFill/></a>
      <a href='https://v-extechsolution.in/learning/reactjs/intro' target='_blank' >Reactjs<GrReactjs/></a>
    </div>
  </div> 


  <div className="dropdown">
    <button className="dropbtn">Backend
    <IoMdArrowDropdownCircle/>
    </button>
    <div className="dropdown-content">
     <a href='https://v-extechsolution.in/mongodb/intro' target='_blank' style={Link} >Mongo Db <SiMongodb/></a>
     <a href='https://v-extechsolution.in/learning/express-js/intro' target='_blank' >Express Js<SiExpress size={18}/></a>
     <a style={Link}>Node Js <IoLogoNodejs size={18}/></a>
    </div>
  </div> 

  <div className="dropdown">
    <button className="dropbtn">Python
    <IoMdArrowDropdownCircle/>
    </button>
    <div className="dropdown-content">
    <a style={Link}>Core Python <SiNumpy/></a>
  
     
    </div>
  </div> 

  <div className="dropdown">
    <button className="dropbtn">Data Analysis
    <IoMdArrowDropdownCircle/>
    </button>
    <div className="dropdown-content">
    <a href='https://v-extechsolution.in/learning/python/intro'>Python <SiPython/></a>
    <a style={Link}>Advance Excel <SiMicrosoftexcel/></a>
    <a href='https://v-extechsolution.in/learning/sql/intro' target='_blank'>MS SQL <TbSql size={25}/></a>
    <a style={Link}>Power Bi <SiPowerbi/></a>
    <a href='https://v-extechsolution.in/learning/excel/intro' target='_blank' >Exel <SiTableau/></a>
    </div>
  </div> 

  

  <div className="dropdown">
    <button className="dropbtn">App Development
    <IoMdArrowDropdownCircle/>
    </button>
    <div className="dropdown-content">
      <a href='https://v-extechsolution.in/learning/react-native/intro' target='_blank' >React Native <CgFigma size={23}/></a>
      
    </div>
  </div>

  <div className="dropdown">
    <button className="dropbtn">Tailwind css
    <IoMdArrowDropdownCircle/>
    </button>
    <div className="dropdown-content">
    <a  href='https://v-extechsolution.in/learning/tailwindcss/intro' target='_blank' >Tailwind Css <SiTailwindcss size={20}/></a>
    </div>
  </div>

  <div className="dropdown">
  <button className="dropbtn">UI/UX
  <IoMdArrowDropdownCircle/>
  </button>
  <div className="dropdown-content">
  <a href='https://v-extechsolution.in/learning/html/intro' target='_blank' >Html<ImHtmlFive/></a>
  <a href='https://v-extechsolution.in/learning/css/intro' target='_blank' >Css<BsFiletypeCss/></a>
      <a href='https://v-extechsolution.in/learning/ui-ux/intro' target='_blank' >UI/UX<GrFigma/></a>
    </div>
</div>



  


       </div>
    )
}

export default Category;