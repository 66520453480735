import "./SqlContent.scss";
import { useLocation, useParams } from "react-router-dom";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { FaCopy } from "react-icons/fa";
import { FaRegCopy } from "react-icons/fa";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

import { Helmet } from "react-helmet";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useContext, useEffect, useState } from "react";
import Login from "../../../Login/Login";
import SqlLoop from "../SqlLoop/SqlLoop";
import SQL from '../Json/SqlJson';
import CssJson from '../Json/CssJson';
import CssAJson from '../Json/CssAJson';
import Javas from '../Json/JavasJson';
import CyberJson from '../Json/CyberJson';
import ExcelJson from '../Json/ExcelJson';
import ExpressJson from '../Json/ExpressJson';
import FigmaJson from '../Json/FigmaJson';
import ProjectJson from '../Json/ProjectJson';
import ReactJson from '../Json/ReactJson';
import ReactNativeJson from '../Json/ReactNativeJson';
import HTML from '../Json/HtmlJson';
import TailwindJson from "../Json/TailwindJson";
import MyContext from "../../../../Uttis/MyContext";
import PythonJson from "../Json/PythonJson";
import NextJson from "../Json/NextJsJson";
import PhpJson from "../Json/PhpJson";
import LaravelJson from "../Json/Laraveljson";


const SqlContent = () => {

  const { setCode,setSnack,setMessage } = useContext(MyContext)

  const handleNavigate = (Item) => {
    setCode(Item)
    window.open('/vxeditor', '_blank');

  };

  const location = useLocation()


  const { title, course } = useParams();

  const data =
    (course === 'html') ? HTML :
      (course === 'sql') ? SQL :
        (course === 'css') ? CssJson :
          (course === 'css-advance') ? CssAJson :
            (course === 'javascript') ? Javas :
              (course === 'cyber-security') ? CyberJson :
                (course === 'excel') ? ExcelJson :
                  (course === 'express-js') ? ExpressJson :
                    (course === 'ui-ux') ? FigmaJson :
                      (course === 'project') ? ProjectJson :
                        (course === 'reactjs') ? ReactJson :
                          (course === 'react-native') ? ReactNativeJson :
                            (course === 'tailwindcss') ? TailwindJson :
                            (course === 'python') ? PythonJson :
                            (course === 'nextjs') ? NextJson :
                            (course === 'php') ? PhpJson :
                            (course === 'laravel') ? LaravelJson :
                              null

  const [menuOpen, setMenuOpen] = useState(window.innerWidth > 768 ? true : false);

  const locationmatch = location.pathname.match(`/learning/${course}/intro`)

  const [isLoggedIn, setLoggedIn] = useState(locationmatch ? true : false);






  // useEffect(() => {
  //   locationmatch ? setLoggedIn(true) : setLoggedIn(false)
  // }, [locationmatch])



  const username = `vextech${course}`



  const handleLogin = () => {
    setLoggedIn(true);
  };

  const [copied, setCopied] = useState(false);
  const [store, setStore] = useState('')

  const handleCopy = () => {
setSnack(true)
setMessage('Thanks Copied To Clipboard')
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 500);

  };


  return (

    <>



      <Helmet>
        <title> {`${course} ${title} Tutorial (Solved With Example)`}</title>
        <meta name="title" content={`${course} ${title} Tutorial (Solved With Example).`} />
        <meta name="description" content={`V-Ex Tech Provides Best ${title} Training in Vadodara with Job Placement The course covers modules in variation of langauges... .`} />

      </Helmet>

      {!isLoggedIn ? (


        <Login onLogin={handleLogin} username={username} password="Veer@0409" />

      ) : (

        <div className="sql-main">

          {menuOpen &&

            <div className="sql-left">

              <SqlLoop course={course} title={title} data={data} />

            </div>

          }




          <div className="sql-right">




            <div className="menu-oppen" onClick={() => setMenuOpen(!menuOpen)}>  <HiOutlineMenuAlt2 /></div>




            {data.filter(Item => Item.html_title === title)
              .map((Item) => (




                <>

                  {
                    Item.bootstrap_head_desc.map((Head) => {
                      return (




                        <div className="nesting-main" >
                          <h1 className="nesting-title" >{Head.bootstrap_title_heading} </h1>
                          <pre className="nesting-desc" style={Head.style}>
                            <CopyToClipboard text={Head.bootstrap_title_desc} onCopy={() => handleCopy() || setStore(Head.bootstrap_title_heading)}>
                              {copied && store === Head.bootstrap_title_heading ? <FaCopy /> : <FaRegCopy />}
                            </CopyToClipboard>
                            {Head.bootstrap_title_desc}
                          </pre>
                        
                         {!Head.copy && course.match(/(html|css|javascript|css-advance)/g) && <button className="run_btn" onClick={() => handleNavigate(Head.bootstrap_title_desc)}>run <MdKeyboardDoubleArrowRight /></button>}
                         {Head.img && <img src={Head.img}  alt="" />}
                        </div>

                      )
                    })
                  }











                </>

              ))}







          </div>







        </div>
      )
      }


    </>
  )
};

export default SqlContent;