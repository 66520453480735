import "./Header.scss";
import { useEffect,useState,useContext } from "react";
import {Link, useLocation} from "react-router-dom";
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {FiPhoneCall,FiUser, FiUserCheck} from "react-icons/fi"
import {RiServiceFill} from "react-icons/ri"
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
import logo from "../../assets/logo/logo.png"
import wlogo from "../../assets/logo/white_logo.png"
import 'reactjs-popup/dist/index.css';
import MyContext from "../../Uttis/MyContext";
import { IoChevronDown } from "react-icons/io5";
import Indicate from "../../Common/Indicate_Modal/Indicate";

const Header = () => {
    const [scrolled,setScrolled] = useState(false)

    const location = useLocation()
    
const {openMenu,openSearch,openLeft,navigate,select,setModal,handleselect} =useContext(MyContext)

    const HandleScroll = () =>{
        
        const offset = window.scrollY
        if (offset>300){
            setScrolled(true);

        }else{
            setScrolled(false);
        }
       
    }


    useEffect(()=>{
        window.addEventListener("scroll",HandleScroll)
    },[])


 
  
    return (
        <>
        <header style={{border:select!=='development' && 'none'}} className={`main-header ${scrolled && !location.pathname.includes('it') ? 'sticky-header' : ""} ${location.pathname==='/' && select!=='development' ? 'white-header':''}`}>
            <div className="header-content">

           
                <ul className="left">
                    <Link to="/contact">
                    <div className="icon-part">
                    <FiPhoneCall/> 
                   <li>Contact</li>
                   </div>
                   </Link>

                   <Link to="/service">
                   <div className="icon-part">
                    <RiServiceFill/> 
                   <li> Service</li>
                   </div>
                   </Link>

                   <Link to="/about">
                   <div className="icon-part">
                    <FiUser/> 
                   <li>About us</li>
                   </div>
                   </Link>
                  
                </ul>
            

                
                <div className="center">
                {location.pathname==='/' && select!=='development'
                     ?
                     <img src={wlogo} alt="" onClick={() => navigate('/')||handleselect('development')}/>:
                     <img src={logo} alt="" onClick={() => navigate('/')}/>


                    }
                </div>
              
                <div className="right">
                
           

          
                <button class="animated-button" onClick={() =>window.open('https://components.v-extechsolution.in/','_blank')}>
    
                <span class="text">Free UI Templates</span>
                <span class="circle"></span>
              
              </button>

             
             {select !=='development' && location.pathname==='/' &&
                <div className="pol">
               <li onClick={()=>setModal(true)}> <IoChevronDown />{select}</li>
             <Indicate/>
               </div>

             }
            
              
                   <a href="https://view.v-extechsolution.in/login" target="_blank"> <FiUserCheck size={18}/></a>

                   {(select==='development' || location.pathname!=='/') && (
                    <>
                      <SearchOutlinedIcon onClick={openSearch} />
                      <DownloadingOutlinedIcon onClick={openLeft} />

                        </>
                  )}
                      <MenuOpenOutlinedIcon onClick={openMenu} />
                  

                  
                 
                       
                </div>
            </div>
        </header>

       
               


         
      



            


           


      
        
    
          
        
        </>
    )
    
    
};

export default Header;