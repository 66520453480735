import "./Banner.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Product from "./Json";
import Typewriter from "typewriter-effect";
import { useState } from "react";
import { useEffect } from "react";



const Banner = () => {

 
  
  
   
    return <>

  
    <div className="categories-slider">

    <div className="studentb">
    <Typewriter
              options={{
                strings: ['<span>Our Student</span>', 'Doing Internship.'],
                autoStart: true,
                loop: true,
              }}
            
 

/>
    </div>
        
    <Carousel 
    responsive={{
      desktop: {
        breakpoint: {
          max: 3000,
          min: 1024
         
        },
        items: 7,
        partialVisibilityGutter: 40
      },
      mobile: {
        breakpoint: {
          max: 464,
          min: 0
        },
        items: 3,
        partialVisibilityGutter: 30
      },
      tablet: {
        breakpoint: {
          max: 1024,
          min: 464
        },
        items: 4,
        partialVisibilityGutter: 30
      }
    }}
    autoPlay={true}
     autoPlaySpeed={3000}
     removeArrowOnDeviceType={["tablet", "mobile"]}
     swipeable={true}
  draggable={false}
  infinite={true}
  className="banner-slide"
  pauseOnHover={false}
  slidesToSlide={1}

  

  
     >

        {Product
        .map((Item)=>{
            return(
                <>


<div key={Item.userid} className="borderb">
     
     <img src={Item.student}  height={"100%"} width={"100%"} className="imageb" alt=''/>
     <div className="nameb">{Item.studentname}</div>
     <div className="positionb">-{Item.title}-</div>
     </div>      

              </>
             
             
            )

        })}

     

 
 
</Carousel>
</div>
   

    </>
}

export default Banner;
