import React, { useEffect, useState } from 'react';
import "./Project.scss";
import Proj from './Json';

const Project = () => {
  const [backgroundColor, setBackgroundColor] = useState("0px");

  useEffect(() => {
    const handleScroll = () => {
      if(window.scrollY > 750)  {
        setBackgroundColor("100%");
      } else {
        setBackgroundColor("0px");
      }
    };

    window.addEventListener('scroll', handleScroll);

 
  }, []);

  return (
    <div className="project-main">
      <div className="title-project">Our Work</div>
      <div className="containerm">
        {Proj.map((Item) => (
          <div
            className='card-main'
            key={Item.userid}
          >
          <div className="card" onClick={() => window.open(Item.project_link, '_blank')}>
              <img src={Item.card_img} alt="Avatar" style={{ width: "100%" }} />
              <div className="overlay" style={{height:backgroundColor}}>
                <div className="text">{Item.overlay_text}</div>
                <div className="demo">View</div>
              </div>
            </div>
            <div className="container">
              <h4><b>{Item.project_title}</b></h4>
              <p>{Item.project_desc}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Project;
