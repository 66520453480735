import React, { useContext, useEffect } from 'react'
import './Choose.scss'
import intern from '../../assets/internship.png'
import training from '../../assets/video-conference.png'
import ui from '../../assets/ux-interface.png'
import { MoveRight } from 'lucide-react'
import logo from '../../assets/logo/logo.png'
import MyContext from '../../Uttis/MyContext'
import { Close } from '@mui/icons-material'



const Choose = () => {
    const{handleselect,modal,select} = useContext(MyContext)



 useEffect(()=>{
    if( modal===true){
    document.querySelector('body').style.overflow='hidden'
    }
 },[modal])
  return (

    <>{modal &&

    <div className='choose-main'>
    <div className='c-modal'>

    <Close  className='cio' onClick={() =>handleselect(select)  ||  window.scrollTo(0, 0)}/>
   <div className='mv'>

   <img src={logo} alt=''/>
 
   </div>



    <div className='title'>What Are You Interested In?</div>
    <p>This will customize your screen content</p>

    <div className='looking'>

    {select!=='internship' &&
    <div className='lbo' onClick={()=>handleselect('internship') || window.scrollTo(0, 0) }>
    <img src={intern} alt=''/>
    <MoveRight />
    <li>Searching For an  <span>IT Internship?</span> </li>
    </div>

    }

    {select!=='training' &&
    <div className='lbo' onClick={()=>handleselect('training') || window.scrollTo(0, 0)}>
    <img src={training} alt=''/>
    <MoveRight />
    <li>Searching For an  <span>IT Training?</span> </li>
    </div>

    }

    


    { select !=='development' &&
    <div className='lbo' onClick={()=>handleselect('development') || window.scrollTo(0, 0)}>
    <img src={ui} alt=''/>
    <MoveRight />
    <li>Searching For an  <span>Sowtware Development?</span> </li>
    </div>

    }
   
    </div>
    </div>
    </div>
    }
    </>
  )
}

export default Choose