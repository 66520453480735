

const LaravelJson = 
  
  [
  
  
    {
        "id": 1,
        "html_title": "intro",
        "bootstrap_head_desc": [
          {
            "bootstrap_title_heading": "What is Laravel?",
            "bootstrap_title_desc": "Laravel is a powerful, open-source PHP web framework designed to simplify the development process by providing a robust foundation for building modern web applications. It offers a clean, expressive syntax and a rich ecosystem of tools that streamline common tasks like routing, authentication, database interaction, and more."
          },
          {
            "bootstrap_title_heading": "Why Choose Laravel?",
            "bootstrap_title_desc": "Here's why Laravel should be your next web development framework:\n\n- **Rapid Development:** Laravel's built-in features and tools accelerate development, allowing you to focus on core application logic.\n\n- **Security:** Laravel prioritizes security with features like built-in user authentication and authorization, CSRF protection, and secure coding practices.\n\n- **Community & Support:** Laravel boasts a large and active community of developers, providing extensive documentation, tutorials, and forums for assistance.\n\n- **Scalability:** Laravel is built to handle even the most demanding applications, providing tools for caching, queueing, and horizontal scaling.\n\n- **Elegant Syntax:** Laravel's syntax is clean and expressive, making it enjoyable to write and maintain code."
          },
          {
            "bootstrap_title_heading": "Getting Started with Laravel",
            "bootstrap_title_desc": "To begin your Laravel journey:\n\n1. **Install PHP and Composer:** Ensure you have a recent version of PHP and Composer installed. Resources for both are linked in the 'Intro to PHP' section (link to previous section). Composer helps manage Laravel dependencies.\n\n2. **Create a New Laravel Project:** Navigate your terminal to your desired project directory and run `composer create-project laravel/laravel my-laravel-app` (replace `my-laravel-app` with your project name). This will create a new Laravel project.\n\n3. **Start the Development Server:** Navigate to your project directory in the terminal and run `php artisan serve`. This will start Laravel's built-in development server, allowing you to access it at `http://localhost:8000` (localhost port may vary) in your browser."
          },

          {
            "bootstrap_title_heading": "MVC Architecture",
            "bootstrap_title_desc": "Laravel follows the Model-View-Controller (MVC) architecture, separating concerns and promoting maintainable code:\n\n- **Models:** Represent your application's data and interact with the database using Laravel's Eloquent ORM (Object-Relational Mapper).\n\n- **Views:** Responsible for presenting data to the user. Laravel utilizes Blade templating for creating dynamic views with expressive syntax.\n\n- **Controllers:** Handle user requests, interact with models to retrieve or modify data, and pass data to views for presentation."
          },
          {
            "bootstrap_title_heading": "Routing",
            "bootstrap_title_desc": "Laravel's routing system defines how incoming URL requests map to specific controller actions. Routes can be defined using methods like `get`, `post`, `put`, and `delete`, allowing for handling various HTTP request types."
          },
          {
            "bootstrap_title_heading": "Authentication & Authorization",
            "bootstrap_title_desc": "Laravel provides robust built-in authentication and authorization features to manage user login, registration, and access control. Features like user roles and permissions help secure your application."
          }
        ],
        "coursename": "Laravel Fundamentals (day-1)"
      },

    


]
export default LaravelJson