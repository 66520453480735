import { Helmet } from "react-helmet";
import Banner from "./Banner/Banner";
import Card from "./Card/Card";
import Category from "./Category/Category";
import Collage from "./Collage-slider/Collage";
import DeveService from "./Development-service/Deve-serivice";
import Increment from "./Increament/Increament";
import Payment from "./Paymentpage/Payment";
import Project from "./Projects/Project";
import Review from "./Reviewslider/Review";
import Servicepage from "./Servicepage/Servicepage";

import Landing from "./Landing/Landing";
import Video from "./Video/Video";
import "./Home.scss";
import { useContext, useEffect } from "react";
import MyContext from "../../Uttis/MyContext";
import Internship from "./Internship/Internship";
import Domain from "./Internship/Domain/Domain";
import Choose from "../../Common/Choose/Choose";

const Home = () => {

  const{select,setModal}= useContext(MyContext)

  useEffect(()=>{
if( !localStorage.getItem('sel')){
  setModal(true)
}
  },[])


  return (
    <>


      <Helmet>
        <title>Best Software Company In Vadodara</title>
        <meta name="title" content="Best Coding Classes In Vadodara Nizampura." />
        <meta name="description" content=" Nizampura Python,Html Css Javascript React all courses are availbale here with placement,take practical knowledge about website in vadodara." />
      </Helmet>
     
      <Choose />
     {select!=='development' && <Internship/>}
     {select!=='development' && <Domain/>}
    { select==='development' && <Category />}
      
    {select==='development' && <Landing />}
 
      {select==='development' && <Banner />}  
    {select==='development' && <Project />}

    {select==='development' &&  <DeveService />}

    {select==='development' &&   <Servicepage />}

    {select==='development' &&  <Collage />}
    
    {select==='development' &&   <Card />}

    {select==='development' &&   <Review />}

    {select==='development' &&   <Payment />}

    {select==='development' &&   <Video />}

    {select==='development' &&    <Increment />}
     
        
    
       
    
      
     
    </>
  );
};

export default Home;
